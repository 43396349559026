html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100vh;
}

#__next {
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --handle-size: 2rem;
  --img-gap: .25rem;
}

/* CSS Hack für die Breadcrumbs */

nav.chakra-breadcrumb ol.chakra-breadcrumb__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: baseline;
}
